import React, {useEffect, useState} from 'react';

import { useAlert } from 'react-alert'

import './Register.scss';

const DEFAULT_CLASSNAME = 'register';

const TITLE = 'Регистрация выпускника'

const useValidation = (value, validations) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const [minLength, setMinLength] = useState(true);
  const [email, setEmail] = useState(true);
  const [inputValid, setInputValid] = useState(false);

  useEffect(() => {
    for (const validation in validations) {
      switch (validation) {
        case 'minLength':
          value.length < validations[validation] ? setMinLength(true) : setMinLength(false)
          break;
        case 'isEmpty':
          value ? setIsEmpty(false) : setIsEmpty(true);
          break;
        case 'email':
          value.toLocaleString().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) ? setEmail(false) : setEmail(true);
          break;
      }
    }
  }, [value])

  useEffect(() => {
    if(!isEmpty || !minLength || !email) {
      setInputValid(true);
    } else {
      setInputValid(false);
    }
  }, [isEmpty, minLength, email])

  return {
    isEmpty,
    minLength,
    email,
    inputValid,
  }
}

const useInput = (initialValue, validations) => {
  const [value, setValue] = useState(initialValue);
  const [isDirty, setIsDirty] = useState(false);

  const valid = useValidation(value, validations)

  const onChange = (e) => {
    setValue(e.target.value)
  }

  const onBlur = () => {
    setIsDirty(true)
  }

  return {
    value,
    isDirty,
    ...valid,
    onChange,
    onBlur,
    setValue,
    setIsDirty
  }
}

export const Register = () => {
  const firstName = useInput('', {isEmpty: true, minLength: 4});
  const lastName = useInput('', {isEmpty: true});
  const surname = useInput('', {isEmpty: false, minLength: 4});
  const citizenship = useInput('', {isEmpty: true});
  const university = useInput('', {isEmpty: true});
  const graduationYear = useInput('', {isEmpty: true, minLength: 4});
  const contactPhone = useInput('', {isEmpty: true, minLength: 7});
  const contactEmail = useInput('', {isEmpty: true, email: true});
  const additionalInfo = useInput();

    const alert = useAlert()

    const clearInputFields = () => {
        firstName.setValue('');
        firstName.setIsDirty(false);
        lastName.setValue('');
        lastName.setIsDirty(false);
        surname.setValue('');
        surname.setIsDirty(false);
        citizenship.setValue('');
        citizenship.setIsDirty(false);
        university.setValue('');
        university.setIsDirty(false);
        graduationYear.setValue('');
        graduationYear.setIsDirty(false);
        contactPhone.setValue('');
        contactPhone.setIsDirty(false);
        contactEmail.setValue('');
        contactEmail.setIsDirty(false);
        additionalInfo.setValue('');
        additionalInfo.setIsDirty(false);
    }

    async function postData(url, data) {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data // body data type must match "Content-Type" header
        });
        return await response.json(); // parses JSON response into native JavaScript objects
    }

  const submitForm = (e) => {
      e.preventDefault();

      const dataObj = {
          first_name: firstName.value,
          surname: surname.value,
          last_name: lastName.value,
          citizenship: citizenship.value,
          university: university.value,
          graduation_year: graduationYear.value,
          phone: contactPhone.value,
          email: contactEmail.value,
          additional_info: additionalInfo.value
      }

      const jsonObj = JSON.stringify(dataObj);

      postData('https://testingbackend.xyz/students/', jsonObj)
          .then(() =>  {
              alert.show('Данные успешно отправлены!');
              clearInputFields();
          })
          .catch(() => {
              alert.show('Что-то пошло не так. Попробуйте позже');
          })
  }

  return (
    <div className={DEFAULT_CLASSNAME}>
      <div className={`${DEFAULT_CLASSNAME}_wrapper`}>
        <div className={`${DEFAULT_CLASSNAME}_title`}>{TITLE}</div>
        <form className={`${DEFAULT_CLASSNAME}_form`} onSubmit={(e) => submitForm(e)}>
          <div className={`${DEFAULT_CLASSNAME}_form-group`}>
            <div className={`${DEFAULT_CLASSNAME}_form-group_item`}>
              <label htmlFor={'firstName'}>Имя | First Name</label>
              <input
                id={'firstName'}
                name={'firstName'}
                placeholder={'Введите Имя'}
                type={'text'}
                value={firstName.value || ''}
                onChange={(e) => firstName.onChange(e)}
                onBlur={(e) => firstName.onBlur(e)}
              />
              {firstName.isDirty && firstName.isEmpty && <div className={`${DEFAULT_CLASSNAME}_form-group_item_error`}>{'Поле не может быть пустым'}</div>}
            </div>
            <div className={`${DEFAULT_CLASSNAME}_form-group_item`}>
              <label htmlFor={'surname'}>Фамилия | Surname</label>
              <input
                id={'surname'}
                name={'surname'}
                placeholder={'Введите фамилию'}
                type={'text'}
                value={surname.value || ''}
                onChange={(e) => surname.onChange(e)}
                onBlur={(e) => surname.onBlur(e)}
              />
              {surname.isDirty && surname.isEmpty && <div className={`${DEFAULT_CLASSNAME}_form-group_item_error`}>{'Поле не может быть пустым'}</div>}
            </div>
        </div>
        <div className={`${DEFAULT_CLASSNAME}_form-group`}>
            <div className={`${DEFAULT_CLASSNAME}_form-group_item`}>
              <label htmlFor={'lastName'}>Отчество | Last Name (Опционально)</label>
              <input
                id={'lastName'}
                name={'lastName'}
                placeholder={'Введите ФИО'}
                type={'text'}
                value={lastName.value || ''}
                onChange={(e) => lastName.onChange(e)}
                onBlur={(e) => lastName.onBlur(e)}
              />
            </div>
            <div className={`${DEFAULT_CLASSNAME}_form-group_item`}>
              <label htmlFor={'citizenship'}>Гражданство | Citizenship</label>
              <input
                id={'citizenship'}
                name={'citizenship'}
                placeholder={'Введите гражданство'}
                type={'text'}
                value={citizenship.value || ''}
                onChange={(e) => citizenship.onChange(e)}
                onBlur={(e) => citizenship.onBlur(e)}
              />
              {citizenship.isDirty && citizenship.isEmpty && <div className={`${DEFAULT_CLASSNAME}_form-group_item_error`}>{'Поле не может быть пустым'}</div>}
            </div>
        </div>
          <div className={`${DEFAULT_CLASSNAME}_form-group`}>
            <div className={`${DEFAULT_CLASSNAME}_form-group_item`}>
              <label htmlFor={'university'}>Университет | University</label>
              <input
                id={'university'}
                name={'university'}
                placeholder={'Введите Университет'}
                type={'text'}
                value={university.value || ''}
                onChange={(e) => university.onChange(e)}
                onBlur={(e) => university.onBlur(e)}
              />
              {university.isDirty && university.isEmpty && <div className={`${DEFAULT_CLASSNAME}_form-group_item_error`}>{'Поле не может быть пустым'}</div>}
            </div>
            <div className={`${DEFAULT_CLASSNAME}_form-group_item`}>
              <label htmlFor={'graduationYear'}>Год Окончания | Graduation Year</label>
              <input
                id={'graduationYear'}
                name={'graduationYear'}
                placeholder={'Введите год окончания'}
                type={'text'}
                value={graduationYear.value || ''}
                onChange={(e) => graduationYear.onChange(e)}
                onBlur={(e) => graduationYear.onBlur(e)}
              />
              {graduationYear.isDirty && graduationYear.isEmpty && <div className={`${DEFAULT_CLASSNAME}_form-group_item_error`}>{'Поле не может быть пустым'}</div>}
            </div>
        </div>
          <div className={`${DEFAULT_CLASSNAME}_form-group`}>
            <div className={`${DEFAULT_CLASSNAME}_form-group_item`}>
              <label htmlFor={'contactPhone'}>Контактный телефон | Contact Phone</label>
              <input
                id={'contactPhone'}
                name={'contactPhone'}
                placeholder={'Введите телефон'}
                type={'tel'}
                value={contactPhone.value || ''}
                onChange={(e) => contactPhone.onChange(e)}
                onBlur={(e) => contactPhone.onBlur(e)}
              />
              {contactPhone.isDirty && contactPhone.isEmpty && <div className={`${DEFAULT_CLASSNAME}_form-group_item_error`}>{'Поле не может быть пустым'}</div>}
            </div>
            <div className={`${DEFAULT_CLASSNAME}_form-group_item`}>
              <label htmlFor={'contactEmail'}>Контактный Email | Contact Email</label>
              <input
                id={'contactEmail'}
                name={'contactEmail'}
                placeholder={'Введите Email'}
                type={'email'}
                value={contactEmail.value || ''}
                onChange={(e) => contactEmail.onChange(e)}
                onBlur={(e) => contactEmail.onBlur(e)}
              />
              {contactEmail.isDirty && contactEmail.isEmpty && <div className={`${DEFAULT_CLASSNAME}_form-group_item_error`}>{'Поле не может быть пустым'}</div>}
              {contactEmail.isDirty && !contactEmail.isEmpty && contactEmail.email && <div className={`${DEFAULT_CLASSNAME}_form-group_item_error`}>Введите корректный E-mail</div>}
            </div>
        </div>
        <div className={`${DEFAULT_CLASSNAME}_additional`}>
          <label htmlFor={'additional'}>Дополнительная информация | Additional Information</label>
          <textarea
            id={'additional'}
            name={'additionalInfo'}
            value={additionalInfo.value || ''}
            onChange={(e) => additionalInfo.onChange(e)}
            onBlur={(e) => additionalInfo.onBlur(e)}
            placeholder={"Введите дополнительную информацию..."} />
        </div>
        <div className={`${DEFAULT_CLASSNAME}_extra`}>{'Регистрируясь вы подтверждайте согласие на обработку персональных данных'}</div>
        <button
          disabled={
            !firstName.inputValid || !surname.inputValid || !citizenship.inputValid ||
            !university.inputValid || !graduationYear.inputValid ||
            !contactPhone.inputValid || !contactEmail.inputValid || contactEmail.email
          }
          type={'submit'}
          className={`${DEFAULT_CLASSNAME}_btn`}
        >{'Зарегистрироваться'}
        </button>
        </form>
      </div>
    </div>
  )
}