import React from 'react';

import './Contacts.scss';

const DEFAULT_CLASSNAME = 'contacts';

export const Contacts = () => {
  return (
    <div className={DEFAULT_CLASSNAME}>
      <div className={`${DEFAULT_CLASSNAME}_wrapper`}>
        <div className={`${DEFAULT_CLASSNAME}_title`}>{'МЕЖДУНАРОДНАЯ АССОЦИАЦИЯ ВЫПУСКНИКОВ ВУЗОВ'}</div>
        <div className={`${DEFAULT_CLASSNAME}_info`}>
          <div>
            <span>проспект Дзержинского 95</span>
            <span>г. Минск, Республика Беларусь</span>
            <span>e-mail: mavvuz.belarus@gmail.com</span>
          </div>
          <div className={'right-alignment'}>
            <span>тел: +375 44 505 90 32</span>
            <span>+375 29 750 24 65</span>
            <span>Сайт: www.mavvuz.by</span>
          </div>
        </div>
      </div>
    </div>
  )
}