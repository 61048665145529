import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.scss';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { App } from "./components/App/App";

const options = {
    position: positions.TOP_CENTER,
    timeout: 5000,
    offset: '30px',
    transition: transitions.SCALE
}

ReactDOM.render(
  <BrowserRouter>
      <AlertProvider template={AlertTemplate} {...options}>
            <App />
      </AlertProvider>
  </BrowserRouter>,
  document.getElementById('root')
);