import React, {useEffect, useState} from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { Pagination } from "swiper";

import './Main.scss';

import rector from '../../assets/rector.mp4';
import {useNavigate} from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";

const DEFAULT_CLASSNAME = 'main';

const Relations = ({ relations, slidedPerView }) => {

  return (
    <div className={`${DEFAULT_CLASSNAME}_relationships_content`}>
      <Swiper
        modules={[Pagination]}
        pagination={true}
        slidesPerView={slidedPerView}
        spaceBetween={50}
        slidesPerGroup={slidedPerView}
      >
        {relations.map((item) => {
          return (
            <SwiperSlide>
              <div className={`${DEFAULT_CLASSNAME}_relationships_item`} style={{padding: item.padding ? '14px 16px' : '8px 16px'}}>
                <RelationItem link={item.image} />
                <p>{item.title}</p>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

const RelationItem = ({ link }) => {
  const [disabled, setDisabled] = useState(false);

  return (
    <img src={link} onError={() => setDisabled(true)} style={{ display: disabled && "none"}} />
  )
}


export const Main = ({relations, partnership, partnership2, partnership3, rukovodstvo, directsiya}) => {
  const [lastNews, setLastNews] = useState([]);

  const [meetingsFull, setMeetingsFull] = useState(false);

  const [slidedPerView, setSlidesPerView] = useState(4);

  const navigate = useNavigate();

  useEffect(() => {
    let urlToFetch = "https://api.mavvuz.by/news";

    if (window.innerWidth <= 500) {
      setSlidesPerView(1);
    }

    fetch(urlToFetch, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => res.json()).then((data) => {
      setLastNews(data.results.slice(0, 3));
    })
  }, []);

  return (
    <div className={DEFAULT_CLASSNAME}>
      <div className={`${DEFAULT_CLASSNAME}_wrapper`}>
        <p className={`${DEFAULT_CLASSNAME}_about_title`}>
          {'Общественное объединение «Международная ассоциация выпускников вузов»'}
          <p>{'(МАВВУЗ)'}</p>
        </p>
        <div className={`${DEFAULT_CLASSNAME}_about`}>
          <br />
          <div className={`${DEFAULT_CLASSNAME}_about_text`}>
            <p>
              {'Зарегистрировано в Министерстве Юстиции Республики Беларусь, свидетельство № 02026 от 10.02.2009 г. Было создано в 2007 году на базе международной ассоциации Белорусского Национального Технического университета. На данный момент Ассоциация объединяет выпускников более чем 100 стран мира.'}
            </p>
            <br />
            <p>
              {'Вся деятельность Ассоциации за истекший период была направлена, прежде всего, на поддержание связи выпускников со своей Альма-матер, а также на установление и поддержание тесных дружественных связей между всеми выпускниками высших учебных заведений Республики Беларусь, на оказание им моральной и правовой поддержки в повышении их профессиональной квалификации и переподготовке в вузах Республики Беларусь, России и других стран, а также на расширение и углубление международного сотрудничества в области образования, экономики, культуры и укрепление авторитета белорусских вузов на международном уровне.'}
            </p>
            <br />
            <p>
              {'МАВВУЗ активно участвует в мировом движении выпускников высших учебных заведений. Всемирные форумы и встречи выпускников советских вузов, проходящие в разных странах, в работе которых принимают участие белорусы, проживающие на территории этой страны и выпускники белорусских вузов, еще раз подчеркивают важность и необходимость деятельности объединений выпускников всех континентов. Десятки тысяч иностранных выпускников советских и белорусских вузов, являясь ядром национальной, научной, технической и культурной интеллигенции, сегодня занимают высокие посты и активно участвуют в решении социально-экономических задач в своих странах.'}
            </p>
          </div>
          <div className={`${DEFAULT_CLASSNAME}_about_lastNews`}>
            <div className={`${DEFAULT_CLASSNAME}_about_lastNews_title`}>{"Последние события"}</div>

            {lastNews.map(item => {
              return (
                <div key={item.pk.toString()} onClick={() => navigate(`/events/${item.pk}`)} className={`${DEFAULT_CLASSNAME}_about_lastNews_item`}>
                  <div className={`${DEFAULT_CLASSNAME}_about_lastNews_item_content`}>
                    <img alt={''} src={item.cover_image} />
                    <div className={`${DEFAULT_CLASSNAME}_about_lastNews_item_content_title`}>
                      {item.title}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
          <div className={`${DEFAULT_CLASSNAME}_intro_team_group_title`} style={{color: "#84a3d6", fontSize: "24px"}}>Руководство</div>
          <div className={`${DEFAULT_CLASSNAME}_intro`}>
          <div className={`${DEFAULT_CLASSNAME}_intro_team`}>
              <div className={`${DEFAULT_CLASSNAME}_intro_team_group`}>
                  <div className={`${DEFAULT_CLASSNAME}_intro_team_group_content`}>
                      {rukovodstvo.map((main) => {
                          return (
                              <div className={`${DEFAULT_CLASSNAME}_intro_team_item`}>
                                  <div>
                                      <img src={main.image} alt={'image'}/>
                                  </div>
                                  <div className={`extra-padding`}>
                                      <p style={{ color: "#b4b5ac", marginBottom: "12px"}}>{main.role}</p>
                                      <p style={{ color: "#84a3d6"}}>{main.name}</p>
                                      <p style={{ color: "#84a3d6"}}>{main.country}</p>
                                  </div>
                              </div>
                          )
                      })}
                  </div>
              </div>
            <div className={`${DEFAULT_CLASSNAME}_intro_team_group_title`} style={{color: "#84a3d6", fontSize: "24px"}}>Дирекция</div>
            <div className={`${DEFAULT_CLASSNAME}_intro_team_group`}>
              <div className={`${DEFAULT_CLASSNAME}_intro_team_group_content`}>
                {directsiya.map((dirik) => {
                  return (
                    <div className={`${DEFAULT_CLASSNAME}_intro_team_item`}>
                      <div>
                        <img src={dirik.image} alt={'image'}/>
                      </div>
                      <div>
                        <p style={{ color: "#b4b5ac", marginBottom: "12px"}}>{dirik.role}</p>
                        <p style={{ color: "#84a3d6"}}>{dirik.name}</p>
                        <p style={{ color: "#84a3d6"}}>{dirik.country}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={`${DEFAULT_CLASSNAME}_relationships_title`}>{'Сотрудничество МАВВУЗ с национальными объединениями'}</div>
        <div className={`${DEFAULT_CLASSNAME}_relationships`}>
          <Relations slidedPerView={slidedPerView} relations={relations}></Relations>
        </div>
          <div className={`${DEFAULT_CLASSNAME}_meetings_title`}>{'Официальные встречи выпускников'}</div>
          <div className={`${DEFAULT_CLASSNAME}_meetings`}>
              <ul className={`${DEFAULT_CLASSNAME}_meetings-etc`}>
                  {meetingsFull ? <>
                    <li>{'I Международная конференция МАВВУЗ (февраль 2008 года)'}</li>
                    <li>{'I Международный форум выпускников "Образование без границ" (май 2008 года)'}</li>
                    <li>{'VII сеарабская встреча выпускников советских и российских вузов в Бейруте (5-10 апреля 2011 года)'}</li>
                    <li>{'Круглый стол на тему "Пути повышения привлекательности и конкурентоспособности системы образования Республики Беларусь на мировом рынке образовательных услуг: проблемы и перспективы" (15 марта 2012 года)'}</li>
                    <li>{'Форум иностранных выпускников белорусских вузов в Марокко (апрель 2012 года)'}</li>
                    <li>{'Международная конференция выпускников советских вузов в Нигерии (июнь 2012 года)'}</li>
                    <li>{'Первый форум выпускников белорусских вузов в Кыргыщстане (октябрь 2012 года)'}</li>
                    <li>{'Участие в Третьем Всемирном форуме иностранных выпускников российских (советских) вузов в Москве (28-30 ноября 2012 г.)'}</li>
                    <li>{'Круглый стол на тему "Взаимодействие вузов с выпускниками: пути продвижения услуг образования на зарубежные рынки" на базе БГУИР (28 февраля 2013 года)'}</li>
                    <li>{'Пленарное (расширенное) заседание совета МАВВУЗ "Международная ассоциация выпускников ВУЗов как центр гражданской дипломатии и сотрудничества в области технического образования (БНТУ)" (февраль 2014 года)'}</li>
                    <li>{'Участие МАВВУЗ в Международной конференции выпускников белорусских вузов в Кыргызстане (август 2014 года)'}</li>
                    <li>{'Создание общественного объединения "Минчанин" во Вьетнаме, при активном участии вьетнамских выпускников Минских вузов и члена Совета МАВВУЗ Ле Бик Тьям (сентябрь 2014 года)'}</li>
                    <li>{'Участие в конференции Клубов выпускников российских и белорусских (советских) вузов в Варшаве (ноябрь 2014 г.)'}</li>
                    <li>{'Участие в Форуме иностранных выпускников российских высших учебных заведений в Москве (29.07.2015-01.08.2015 г.);'}</li>
                    <li>{'Участие в торжественной церемонии открытия подготовительного отделения на базе института А.С. Пушкина в Марокко (организатор – ИнкорВуз) (ноябрь 2015 года)'}</li>
                    <li>{'Второй Образовательный Форум иностранных выпускников стран СНГ (советских) вузов «Традиции + инновации – гарант успеха современной высшей школы» (25-28 мая 2016 г. Организаторы: ВАВ; ИнкорВуз, МАВВУЗ);'}</li>
                    <li>{'Круглый стол "Повышение конкурентоспособности высшего технического образования в современном мире" в честь 75-летия Победы советского народа в Великой Отечественной войне. (10.07.2020 г.)'}</li>
                    <li>{'Международный Образовательный Форум единства иностранных выпускников белорусских вузов и стран СНГ (04-09 октября 2021 г.)'}</li>
                  </> : <>
                    <li>{'I Международная конференция МАВВУЗ (февраль 2008 года)'}</li>
                    <li>{'I Международный форум выпускников "Образование без границ" (май 2008 года)'}</li>
                    <li>{'VII сеарабская встреча выпускников советских и российских вузов в Бейруте (5-10 апреля 2011 года)'}</li>
                    <li>{'Круглый стол на тему "Пути повышения привлекательности и конкурентоспособности системы образования Республики Беларусь на мировом рынке образовательных услуг: проблемы и перспективы" (15 марта 2012 года)'}</li>
                    <li>{'Форум иностранных выпускников белорусских вузов в Марокко (апрель 2012 года)'}</li>
                    <li>{'Международная конференция выпускников советских вузов в Нигерии (июнь 2012 года)'}</li>
                    <li>{'Первый форум выпускников белорусских вузов в Кыргыщстане (октябрь 2012 года)'}</li>
                  </>}
              </ul>
              <div onClick={() => setMeetingsFull(!meetingsFull)} className={`${DEFAULT_CLASSNAME}_meetings_all`}>{meetingsFull ? "Скрыть" : "Показать полностью..."}</div>
          </div>
        <div className={`${DEFAULT_CLASSNAME}_partnership_title`}>{'ВУЗы - Партнёры'}</div>
        <div className={`${DEFAULT_CLASSNAME}_partnership`}>
          <Swiper
            modules={[Pagination]}
            pagination={true}
            slidesPerView={slidedPerView}
            spaceBetween={50}
            slidesPerGroup={slidedPerView}
          >
            {[...partnership, ...partnership2, ...partnership3].map(item => {
              return (
                <SwiperSlide>
                  <div className={`${DEFAULT_CLASSNAME}_partnership_item`}>
                    <img src={item.url} alt={'part'} />
                    <p>{item.title}</p>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
          <div className={`${DEFAULT_CLASSNAME}_video_wrapper`}>
              <video src={rector} width="100%" height="auto" controls="controls" />
          </div>
      </div>
    </div>
  )
}