import React from 'react';

import './Header.scss';
import mavvuz from "../../assets/mavvuz.png";
import government from "../../assets/logoGovernment.jpg";
import header from '../../assets/header.jpg';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faNewspaper, faAward, faHandshake, faRightToBracket, faGraduationCap, faFile } from '@fortawesome/free-solid-svg-icons'


const home = <FontAwesomeIcon icon={faHome} />
const award = <FontAwesomeIcon icon={faAward} />
const hands = <FontAwesomeIcon icon={faHandshake} />
const login = <FontAwesomeIcon icon={faRightToBracket} />
const newsPaper = <FontAwesomeIcon icon={faNewspaper} />
const graduation = <FontAwesomeIcon icon={faGraduationCap} />
const file = <FontAwesomeIcon icon={faFile} />


const DEFAULT_CLASSNAME = 'header';

export const Header = () => {
  return (
    <div className={DEFAULT_CLASSNAME}>
      <div className={`${DEFAULT_CLASSNAME}_wrapper`}>
        <div className={`${DEFAULT_CLASSNAME}_wrapper_back`}>
        <div className={`${DEFAULT_CLASSNAME}_wrapper_logo`}>
          <img src={government} alt={'government'} />
          <img src={mavvuz} alt={'маввуз лого'}/>
        </div>
        <div className={`${DEFAULT_CLASSNAME}_wrapper_logo_speech`}>
          <p>{'"...Выражаю надежду, что вы внесете достойный вклад в развитие взаимовыгодных контактов в различных сферах экономической и социальной жизни, будете способствовать укреплению дружбы между народами" - Приветствие участникам I Международного форума иностранных выпускников белорусских (советских) вузов "Образование без границ"'}</p>
          <span>{'Президент Республики Беларусь А.Г. Лукашенко'}</span>
        </div>
        <div className={`${DEFAULT_CLASSNAME}_nav`}>
          <NavLink activeClassName={'active'} to={'/'} className={`${DEFAULT_CLASSNAME}_nav_link`}>{home}<span>{'Главная'}</span></NavLink>
          <NavLink activeClassName={'active'} to={'/associations'} className={`${DEFAULT_CLASSNAME}_nav_link`}>{award}<span>{'Сотрудничество'}</span></NavLink>
          <NavLink activeClassName={'active'} to={'/partnership'} className={`${DEFAULT_CLASSNAME}_nav_link`}>{hands}<span>{'ВУЗы - Партнёры'}</span></NavLink>
          <NavLink activeClassName={'active'} to={'/graduation'} className={`${DEFAULT_CLASSNAME}_nav_link`}>{graduation}<span>{'Наши выпускники'}</span></NavLink>
          <NavLink activeClassName={'active'} to={'/events'} className={`${DEFAULT_CLASSNAME}_nav_link`}>{newsPaper}<span>{'События'}</span></NavLink>
          <NavLink activeClassName={'active'} to={'/ustav'} className={`${DEFAULT_CLASSNAME}_nav_link`}>{file}<span>{'Уставные документы'}</span></NavLink>
          <NavLink activeClassName={'active'} to={'/register'} className={`${DEFAULT_CLASSNAME}_nav_link`}>{login}<span>{'Регистрация'}</span></NavLink>
        </div>
        </div>
      </div>
    </div>
  )
}