import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import './StudentPage.scss';
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";

const DEFAULT_CLASSNAME = 'student-page';

export const StudentPage = () => {
    let navigate = useNavigate();

    const onBackHandler = () => {
        navigate('/graduation');
    }

    const [currentEvent, setCurrentEvent] = useState(null);

    const [slidedPerView, setSlidesPerView] = useState(4);

    useEffect(() => {
        const eventId = window.location.pathname.slice(12)

        if (window.innerWidth <= 500) {
            setSlidesPerView(1);
        }

        fetch(`https://api.mavvuz.by/news/${eventId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then((data) => {
            setCurrentEvent(data)
        })
    }, []);

    return (
        <div className={DEFAULT_CLASSNAME}>
            <div className={`${DEFAULT_CLASSNAME}_wrapper`}>
                <div className={`${DEFAULT_CLASSNAME}_link-back`} onClick={() => onBackHandler()}>{'Назад'}</div>
                <div className={`${DEFAULT_CLASSNAME}_title`}>{currentEvent?.title}</div>

                <div className={`${DEFAULT_CLASSNAME}_texts`}>{currentEvent?.paragraphs?.map((paragraph) => {
                    return <>
                        <div style={{ fontWeight: "500" }}>{paragraph.subtitle}</div>
                        <div style={{ textAlign: "justify" }}>{paragraph.content}</div>
                    </>
                })}</div>

                <div className={`${DEFAULT_CLASSNAME}_images`}>
                    <Swiper
                      className={"swiper_eventPage"}
                      modules={[Pagination]}
                      pagination={true}
                      slidesPerView={slidedPerView}
                      spaceBetween={0}
                      slidesPerGroup={slidedPerView}
                    >
                        {currentEvent?.photos?.map((photo) => {
                            return <SwiperSlide style={{ display: "flex", justifyContent: 'center', alignItems: "center"}}>
                                <img src={photo.image} alt={'img'} />
                            </SwiperSlide>
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}