import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './Events.scss';

const DEFAULT_CLASSNAME = 'events'

export const Events = () => {
    let navigate = useNavigate();

    const [beEvents, setEvents] = useState([]);
    const [filters, setFilters] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState(null);

    const navigateEventHandler = (eventId) => {
        navigate(`${eventId}`)
    }

    useEffect(() => {
        let urlToFetch = "https://api.mavvuz.by/news";

        if (selectedCategory) urlToFetch += `?category=${selectedCategory}`;

        fetch(urlToFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then((data) => {
            setEvents(data.results)
        })

        fetch(`https://api.mavvuz.by/categories`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then(data => {
            setFilters(data.results);
        })
    }, [selectedCategory, setEvents, setFilters]);

    return (
        <div className={DEFAULT_CLASSNAME}>
            <div className={`${DEFAULT_CLASSNAME}_wrapper`}>
                <div className={`${DEFAULT_CLASSNAME}_title`}>
                    {'События'}
                </div>
                <div className={`${DEFAULT_CLASSNAME}_categories`}>
                    {filters.map(item => <div onClick={() => setSelectedCategory(selectedCategory === item.pk ? null : item.pk)} key={item.pk} className={`${DEFAULT_CLASSNAME}_categories_item ${selectedCategory === item.pk && "selected_category"}`}>{item.name}</div>)}
                </div>
                <div className={`${DEFAULT_CLASSNAME}_container`}>
                    {beEvents.map((event, id) => {
                        return (
                            <div className={`${DEFAULT_CLASSNAME}_item`} key={id.toString()} onClick={() => navigate(`/events/${event.pk}`)}>
                                <div className={`${DEFAULT_CLASSNAME}_item_content`}>
                                    <img alt={''} src={event.cover_image} />
                                    <span className={`${DEFAULT_CLASSNAME}_item-title`}>
                                        {event.title}
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}