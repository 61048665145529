import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import './EventPage.scss';
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";

const DEFAULT_CLASSNAME = 'event-page';

export const EventPage = () => {
    let navigate = useNavigate();

    const onBackHandler = () => {
        navigate(-1);
    }

    const [currentEvent, setCurrentEvent] = useState(null);

    const [slidedPerView, setSlidesPerView] = useState(4);

    useEffect(() => {
        const eventId = window.location.pathname.slice(8);

        if (window.innerWidth <= 500) {
            setSlidesPerView(1);
        }

        fetch(`https://api.mavvuz.by/news/${eventId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then((data) => {
            setCurrentEvent(data)
        });

    }, []);

    const [openFullImage, setOpenFullImage] = useState(null);

    return (
        <div className={DEFAULT_CLASSNAME}>
            {openFullImage && <div className={`${DEFAULT_CLASSNAME}_fullImage`} onClick={() => setOpenFullImage(null)}>
                <img src={openFullImage} alt={'full_image'} />
            </div>}

            <div className={`${DEFAULT_CLASSNAME}_wrapper`}>
                <div className={`${DEFAULT_CLASSNAME}_link-back`} onClick={() => onBackHandler()}>{'Назад'}</div>
                <div className={`${DEFAULT_CLASSNAME}_title`}>{currentEvent?.title}</div>

                <div className={`${DEFAULT_CLASSNAME}_texts`}>
                    <div dangerouslySetInnerHTML={{__html: currentEvent?.short_description}}></div>
                </div>

                <div style={{fontWeight: '700', padding: '12px'}}>{currentEvent?.category_name === "Доска почёта" ? "Фото / Видео" : "Фото / Видео с мероприятия"}</div>

                {!!currentEvent?.yt_links.length && <div>
                    {currentEvent?.yt_links.map(item => (
                      <iframe
                        src={`https://www.youtube.com/embed/${item.content.split('/')[item.content.split('/').length - 1]}`}
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                        width="100%"
                        height="100%"
                        scrolling="no"
                        frameBorder="0"
                      >
                      </iframe>
                      ))}
                </div>}

                <div className={`${DEFAULT_CLASSNAME}_images`}>
                    <Swiper
                      className={"swiper_eventPage"}
                      modules={[Pagination]}
                      pagination={true}
                      slidesPerView={slidedPerView}
                      spaceBetween={0}
                      slidesPerGroup={slidedPerView}
                    >
                        {currentEvent?.photos?.map((photo) => {
                            return <SwiperSlide onClick={() => setOpenFullImage(photo.image)} style={{ cursor: "pointer", display: "flex", justifyContent: 'center', alignItems: "center", paddingBottom: "20px"}}>
                                <img src={photo.image} alt={'img'} />
                            </SwiperSlide>
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}