import React, {useEffect, useState} from 'react';
import './Graduation.scss';
import { useNavigate } from "react-router-dom";

const DEFAULT_CLASSNAME = 'graduation'

export const Graduation = () => {
    let navigate = useNavigate();

    const [beStudents, setStudents] = useState([]);

    const navigateEventHandler = (eventId) => {
        navigate(`${eventId}`)
    }

    useEffect(() => {
        fetch("https://api.mavvuz.by/news?category=db26d939-2626-4bb9-add6-a0bceeb16dc5", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then((data) => {
            setStudents(data.results)
        })
    }, []);

  return (
    <div className={`${DEFAULT_CLASSNAME}`}>
      <div className={`${DEFAULT_CLASSNAME}_wrapper`}>
        <div className={`${DEFAULT_CLASSNAME}_title`}>{"Наши выпускники"}</div>
        <div className={`${DEFAULT_CLASSNAME}_sub_title`}>{"всё о чем хочется поделиться с друзьями (воспоминания, успехи, надежды, мысли, письма и др.)"}</div>
          <div className={`${DEFAULT_CLASSNAME}_container`}>
              {beStudents?.map((student, id) => {
                  return (
                    <div className={`${DEFAULT_CLASSNAME}_item`} key={id.toString()} onClick={() => navigate(`/events/${student.pk}`)}>
                      <div className={`${DEFAULT_CLASSNAME}_item_content`}>
                        <img alt={''} src={student.cover_image} />
                        <span className={`${DEFAULT_CLASSNAME}_item-title`}>{student.title}</span>
                      </div>
                    </div>
                  )
              })}
          </div>
      </div>
    </div>
  )
}
