import React, { useState, useEffect, memo } from 'react';

function A() {
  console.log(2);
  return <B />
}

const B = memo(() => {
  console.log(3);
  const child = "hello, world";

  return <C>{child}</C>
})

function C({children}) {
  console.log(4);
  return <p>{children}</p>;
}

function D() {
  console.log(5);
  return null;
}

export const TestComponent = () => {
  const [state, setState] = useState(0);

  useEffect(() => {
    setState(state => state + 1);
  }, [])

  console.log(1);

  return (
    <div>
      <A state={state} />
      <D />
    </div>
  )
}