import React from 'react'
import ustav from "../../assets/ustav.jpg";
import svid from "../../assets/svid.jpg";

import './Ustav.scss';

export const Ustav = () => {
  return (
    <div className={`ustav`}>
        <div className={`ustav_wrapper`}>
        <div className={`main_ustav_title`}>{'Устав и Свидетельство МАВВУЗ'}</div>
        <div className={`main_ustav`}>
          <img src={svid} alt={'Свидетельство'}/>
          <img src={ustav} alt={'Устав'}/>
        </div>
      </div>
    </div>
  )
}