import React, {useState} from 'react';

import './Associations.scss';

const DEFAULT_CLASSNAME = 'associations';

const RelationItem = ({ link }) => {
  const [disabled, setDisabled] = useState(false);

  return (
    <img src={link} onError={() => setDisabled(true)} style={{ display: disabled && "none"}} />
  )
}

export const Associations = ({relations}) => {
  return (
    <div className={DEFAULT_CLASSNAME}>
      <div className={`${DEFAULT_CLASSNAME}_wrapper`}>
        <div className={`${DEFAULT_CLASSNAME}_title`}>
          {'Сотрудничество МАВВУЗ с национальными объединениями'}
        </div>
        <div className={`${DEFAULT_CLASSNAME}_container`}>
          {relations.map(rel => {
            return <div className={`${DEFAULT_CLASSNAME}_relationships_item`}>
              <div className={`${DEFAULT_CLASSNAME}_image_wrapper`}>
                <RelationItem link={rel.image} />
              </div>
              <div className={`${DEFAULT_CLASSNAME}_relationships_item--text`}>
                <div>
                  <span>{rel.title}</span>
                  <p>{rel.text}</p>
                </div>
                <a className={`${DEFAULT_CLASSNAME}_relationships_item--link`} target={'_blank'} href={rel.link}>Подробнее</a>
              </div>

            </div>
          })}
        </div>
      </div>
    </div>
  )
}