import React from 'react';

import './Partnership.scss';
import bguki from '../../assets/bguki.png';

const DEFAULT_CLASSNAME = 'partnership';

const partners = [
  {
    title: 'БГАТУ (Белорусский Государственный Аграрный Технический университет)',
    text: '"БГАТУ – ведущий образовательный, научный и инновационный центр Республики Беларусь по подготовке высококвалифицированных кадров для отраслей агропромышленного комплекса. Около 12 тысяч обучающихся; 6 факультетов, 12 специальностей на первой ступени высшего образования, 6 – на второй ступени высшего образования (магистратура); 14 специальностей по подготовке научных работников высшей квалификации в аспирантуре и 6 – в докторантуре',
    imgUrl: 'https://lh3.googleusercontent.com/p/AF1QipN4PB12ZygFPdmzCwirwrWUasMQP8uQZm1gybXh=s1360-w1360-h1020',
    link: 'https://www.bsatu.by/ru',
  },
  {
    title: 'МИТСО Международный университет "МИТСО"',
    text: 'Международный университет «МИТСО́» — учреждение высшего образования в Белоруссии. Основан 15 марта 1930 года. Ежегодно обучается более 4000 студентов. В университете работают 28 докторов наук, профессоров; 144 кандидата наук, доцента.',
    imgUrl: 'https://lh3.googleusercontent.com/p/AF1QipMkpixUwJOm8iBx9b_SEbHJJkVfxurWC2vjRTeu=s1360-w1360-h1020',
    link: 'https://mitso.by/ru/',
  },
  {
    title: 'БГПУ им. М. Танка (Белорусский Государственный Педагогический университет им. М. Танка)',
    text: 'Белорусский государственный педагогический университет имени Максима Танка — ведущее высшее учебное заведение педагогического профиля Республики Беларусь, один из старейших вузов в стране. Назван в честь белорусского народного поэта.',
    imgUrl: 'https://lh3.googleusercontent.com/p/AF1QipNcljtA-W0tbAKZ0EwZpz-Qp-jkNDcJ_LWRh8Q7=s1360-w1360-h1020',
    link: 'https://bspu.by/',
  },
  {
    title: 'ПолесГУ (Полесский Государственный университет)',
    text: 'Университет был создан 5 апреля 2006 года Указом Президента Республики Беларусь № 203 на базе Пинского филиала Белорусского государственного экономического университета и Пинского государственного высшего банковского колледжа Национального банка Беларуси. С 2006 по 2013 год университет подчинялся Национальному банку Республики Беларусь[1], Указом Президента Республики Беларусь № 141 от 2 апреля 2013 года учреждение образования передано в подчинение Министерства образования Республики Беларусь',
    imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/0/08/3_%D0%BA%D0%BE%D1%80%D0%BF%D1%83%D1%81.jpg',
    link: 'https://www.polessu.by/',
  },
  {
    title: 'ВГУ (Витебский Государственный университет)',
    text: 'Витебский государственный университет имени П. М. Машерова — научно-образовательный центр Белоруссии, одно из старейших высших учебных заведений страны.',
    imgUrl: 'https://lh3.googleusercontent.com/p/AF1QipNIAjRp0fQSBjZBnZY2JK4ijpAD46O2YB27iEoz=s1360-w1360-h1020',
    link: 'https://vsu.by/universitet/ob-universitete.html',
  },
  {
    title: 'РИВШ (Республиканский институт высшей школы)',
    text: '"Республиканский институт высшей школы является учреждением образования взрослых, основная задача которого – повышение квалификации и переподготовка профессорско-преподавательского и административно-управленческого состава учреждений высшего образования и учреждений дополнительного образования взрослых. Одновременно институт осуществляет научно-методическое обеспечение функционирования и развития систем высшего образования и дополнительного образования взрослых."',
    imgUrl: 'https://nihe.bsu.by/images/2015/actual-photo/14.jpg',
    link: 'https://nihe.bsu.by/index.php/ru/',
  },
  {
    title: 'БГУИР (Белорусский Государственный университет информатики радиоэлектроники)',
    text: 'Белорусский государственный университет информатики и радиоэлектроники, также известный как БГУИР, является государственным вузом, аккредитованным Министерством образования Республики Беларусь. В настоящее время это крупный образовательный и научный комплекс в Минске, Беларусь. БГУИР был основан 15 марта 1964 года и играет ведущую роль в подготовке своих студентов в области компьютерных наук, радиоэлектроники и телекоммуникаций в Беларуси.',
    imgUrl: 'https://severvent.by/wp-content/uploads/2019/10/0bb6b24feabe8e328517c2ca1eb6c465.jpg',
    link: 'https://www.bsuir.by',
  },
  {
    title: 'БГЭУ (Белорусский Государственный Экономический университет)',
    text: 'Белорусский государственный экономический университет - университет в Минске, Беларусь. Он специализируется на финансах и экономике. Он был основан в 1933 году как Белорусский институт народного хозяйства. В январе 1992 года он был переименован в Белорусский государственный экономический университет (БГЭУ). В 1997 году университету был официально присвоен статус ведущего учебного заведения по подготовке специалистов в области ',
    imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/%D0%91%D0%93%D0%AD%D0%A3.jpg/1920px-%D0%91%D0%93%D0%AD%D0%A3.jpg',
    link: 'http://bseu.by',
  },
  {
    title: 'ВГУ (Витебский Государственный университет)',
    text: 'Витебский государственный университет имени П. М. Машерова — научно-образовательный центр Белоруси, одно из старейших высших учебных заведений страны (первым из современных классических университетов республики получил статус вуза в 1918 году).',
    imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/%D0%92%D0%93%D0%A3_2020.jpg/1920px-%D0%92%D0%93%D0%A3_2020.jpg',
    link: 'https://vsu.by/',
  },
  {
    title: 'БГУКИ (Белорусский Государственный университет культуры и исскуств)',
    text: 'Белорусский государственный университет культуры и искусств - государственное высшее учебное заведение в Минске. В его честь названа станция минского метрополитена "Институт культуры"',
    imgUrl: bguki,
    link: 'http://buk.by',
  },
  {
    title: 'БрГТУ (Брестский Государственный Технологический университет)',
    text: 'Брестский государственный технический университет расположен в Бресте. Он начался как Брестский государственный инженерно-строительный институт 1 апреля 1966 года, который в 1989 году был преобразован в Брестский государственный политехнический институт, а в 2000 году - в университет.',
    imgUrl: 'https://static.bntu.by/bntu/new/news/uploads/news-2019/1_1200px-%D0%91%D1%80%D0%93%D0%A2%D0%A3.jpg%7CresizeToWidth=1200',
    link: 'https://www.bstu.by/',
  },
  {
    title: 'ГрГУ им. Я. Купалы (Гродненский Государственный университет имени Янки Купалы)',
    text: 'Гродненский государственный университет имени Янки Купалы - высшее учебное заведение, расположенное в Гродно. Это крупнейшее региональное высшее учебное заведение в Республике Беларусь, университетский комплекс, объединяющий все уровни образования.',
    imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D1%8B%D0%B9_%D0%BA%D0%BE%D1%80%D0%BF%D1%83%D1%81_%D0%93%D1%80%D0%93%D0%A3_%D0%B8%D0%BC._%D0%AF%D0%BD%D0%BA%D0%B8_%D0%9A%D1%83%D0%BF%D0%B0%D0%BB%D1%8B.jpg/1920px-%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D1%8B%D0%B9_%D0%BA%D0%BE%D1%80%D0%BF%D1%83%D1%81_%D0%93%D1%80%D0%93%D0%A3_%D0%B8%D0%BC._%D0%AF%D0%BD%D0%BA%D0%B8_%D0%9A%D1%83%D0%BF%D0%B0%D0%BB%D1%8B.jpg',
    link: 'https://www.grsu.by/by/',
  },
    {
        title: 'Белорусский торгово-экономический университет потребительской кооперации',
        text: 'Университет является аккредитованным крупным образовательным и научно-исследовательским центром республиканского и международного значения с более чем 50-летней историей. За это время для национальной экономики Белоруссии и зарубежных стран подготовлено более 44 тыс. специалистов',
        imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/c/c2/Bteu.jpg',
        link: 'http://www.i-bteu.by/',
    },
    {
        title: 'Белорусская государственная академия связи (Академия связи, БГАС)',
        text: 'Белорусская государственная академия связи (белор. Беларуская дзяржа́ўная акадэмія су́вязі) — высшее учебное заведение Министерства связи и информатизации Республики Беларусь. Основано 11 мая 1993 года с помощью объединения Минского филиала Ленинградского электротехнического института связи им. проф. М. А. Бонч-Бруевича, Минского и Витебского электротехникумов связи и Республиканского центра подготовки, переподготовки и повышения квалификации работников отрасли связи.',
        imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/%D0%9A%D0%BE%D0%BB%D0%BB%D0%B5%D0%B4%D0%B6_%D1%81%D0%B2%D1%8F%D0%B7%D0%B8_2.JPG/1024px-%D0%9A%D0%BE%D0%BB%D0%BB%D0%B5%D0%B4%D0%B6_%D1%81%D0%B2%D1%8F%D0%B7%D0%B8_2.JPG',
        link: 'http://bsac.by/',
    },
];

export const Partnership = () => {
  return (
    <div className={DEFAULT_CLASSNAME}>
      <div className={`${DEFAULT_CLASSNAME}_wrapper`}>
        <div className={`${DEFAULT_CLASSNAME}_title`}>
          {'ВУЗы партнёры'}
        </div>
        <div className={`${DEFAULT_CLASSNAME}_container`}>
          {partners.map((partner, id) => {
            return (
              <div className={`${DEFAULT_CLASSNAME}_item`} key={id.toString()}>
                <div className={`${DEFAULT_CLASSNAME}_item-title`}>
                  <span>{partner.title}</span>
                </div>
                <div className={`${DEFAULT_CLASSNAME}_item_content`}>
                  <img alt={''} src={partner.imgUrl} />
                  <div className={`${DEFAULT_CLASSNAME}_item_content_text`}>
                    {partner.text}
                    <div>
                      <a target={'_blank'} href={partner.link}>{'Перейти на сайт'}</a>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
